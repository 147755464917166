import React, { useState, useContext } from "react";
import RootLayout from "../../ui-components/RootLayout";
import CTA from "../../ui-components/cta/CTA";
import PortfolioCard from "../../ui-components/portfolio/PortfolioCard";
import portfolioData from "../../data/portfolio.json"
import { AppContext } from "../../context/AppContext";

const Portfolio = () => {

  const [portfolioContent, setPortfolioContent] = useState(portfolioData.Portfolio);
  const { dark } = useContext(AppContext);

  return (
    <div>
      <RootLayout>
        <section className='pt-20 mx-auto max-w-7xl p-6 lg:px-8'>
          <h1 className={`${dark ? 'text-white' : ''} relative font-display text-6xl flex justify-center font-semibold`}>Portfolio
            <span aria-hidden="true" className='abstraction-heading md:text-7xl text-6xl text-emerald-50 uppercase'>Portfolio</span>
          </h1>
          <div className="mt-10 grid md:grid-cols-3 gird-cols-1 gap-10">
            {
              portfolioContent.map((portfolio) => (
                <PortfolioCard portfolio={portfolio} />
              ))
            }
          </div>
        </section>
        <CTA />
      </RootLayout>
    </div>
  );
};

export default Portfolio;
