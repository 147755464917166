import React, { useContext } from 'react'
import { AppContext } from '../../context/AppContext'

type ProviderProps = {
  provider: string,
  providers: {
    [key: string]: {
      Description: string,
      Logo: string,
      LearnMore: string,
    }
  }
}

const Provider: React.FC<ProviderProps> = (props) => {

  const { provider, providers } = props;
  const { dark } = useContext(AppContext);

  return (
    <div>
      <div>
        <div className='w-36 h-36 flex items-center justify-center rounded-full bg-white'>
          <img src={providers[provider].Logo} alt={provider} className='w-24' />
        </div>
        <h3 className={`${dark ? "text-white" : ''} text-xl font-semibold font-display my-3`}>{provider}</h3>
        <p className={`${dark ? "text-white" : 'text-gray-800'} text-sm`}>{providers[provider].Description}</p>
      </div>
    </div>
  )
}

export default Provider