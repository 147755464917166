import React, { useContext } from 'react'
import { AppContext } from '../../context/AppContext'

type StepProps = {
  step: {
    step: string,
    title: string,
    description: string,
    icon: string,
    status: string
  }
}

const ProcessStep: React.FC<StepProps> = (props) => {

  const { step } = props;
  const { dark } = useContext(AppContext);

  return (
    <div>
      <div className='flex items-center gap-4'>
        <span className='text-6xl font-semibold text-emerald-300'>{step.step}</span>
        <div className='w-20 h-20 bg-white rounded-lg shadow-lg flex items-center justify-center'>
          <img className='w-full' src={step.icon} alt={step.title} />
        </div>
      </div>
      <h3 className={`${dark ? 'text-white' : 'text-gray-700'} font-bold text-gray-800 text-xl capitalize mt-3`}>{step.title}</h3>
      <p className={`${dark ? 'text-white' : 'text-gray-700'} w-4/5`}>
        {step.description}
      </p>
      <div className='font-semibold text-sm p-2 rounded-lg bg-zinc-100 mt-3 w-max capitalize'>
        {step.status}
      </div>
    </div>
  )
}

export default ProcessStep