import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'


type ServiceContentProps = {
  servicesContent: {
    [key: string]: {
      Image: string,
      List: {
        [key: string]: string
      }
    }
  },
  selectedService: string,
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
}

const ServiceContent: React.FC<ServiceContentProps> = (props) => {

  const { servicesContent, selectedService, setIsOpen, isOpen } = props;

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      <div id={selectedService} className='hidden md:block sticky top-0 p-5 flex-1 rounded-xl h-full' style={{ background: `linear-gradient(180deg, rgba(28,26,52,1) 0%, rgba(28,26,52,0.5160189075630253) 100%), url(${servicesContent[selectedService].Image})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
        <h3 className='md:w-max font-bold font-display font-medium text-4xl text-white mb-10' dangerouslySetInnerHTML={{ __html: selectedService }} />
        <ul>
          {
            Object.keys(servicesContent[selectedService]?.List || []).map((el) => (
              <li className='mb-5' key={el}>
                <h4 className='font-semibold mb-2 text-white'>{el}</h4>
                <p className='text-sm w-full text-white'>{servicesContent[selectedService].List[el]}</p>
              </li>
            ))
          }
        </ul>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 block md:hidden" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    <h3 className='md:w-max font-bold font-display font-medium text-4xl md:text-white text-gray-900 mb-10' dangerouslySetInnerHTML={{ __html: selectedService }} />
                  </Dialog.Title>
                  <ul>
                    {
                      Object.keys(servicesContent[selectedService]?.List || []).map((el) => (
                        <li className='mb-5' key={el}>
                          <h4 className='font-semibold mb-2 md:text-white text-gray-900'>{el}</h4>
                          <p className='text-sm w-full md:text-white text-gray-900'>{servicesContent[selectedService].List[el]}</p>
                        </li>
                      ))
                    }
                  </ul>
                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default ServiceContent