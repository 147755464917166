import React, { useState, useContext } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import clientsData from "../../data/OurClients.json"
import 'swiper/css';
import { AppContext } from '../../context/AppContext';
import { isMobile } from "react-device-detect";


const Client = () => {

  const [clientsContent, setClientsContent] = useState(clientsData);
  const { dark } = useContext(AppContext);

  return (
    <section className='mx-auto max-w-7xl p-6 lg:px-8 pb-10'>
      <h2 className={`${dark ? 'text-white' : ''} relative font-display text-4xl font-semibold mb-10 md:w-1/3 w-full mx-auto text-center`}>{clientsContent.Header}
      </h2>
      <Swiper
        spaceBetween={50}
        centeredSlides={true}
        slidesPerView={isMobile ? 1 : 5}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
      >
        {
          clientsContent.clients.map(client => (
            <SwiperSlide className='flex items-center justify-center'>
              <div>
                <div className='p-2 h-48 w-48 flex items-center justify-center bg-white rounded-lg'>
                  <img className='select-none	w-32' src={client.logo} alt={client.name} />
                </div>
                <div className='shadow-md w-48 p-3 mb-10 text-gray-800 text-center font-semibold capitalize bg-white rounded-lg mt-2'>
                  <h5>{client.name}</h5>
                </div>
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </section>
  )
}

export default Client