// CORE
exports.coreEndpoint = process.env.NODE_ENV === 'development'
?'https://c0m5rweo2e.execute-api.us-east-2.amazonaws.com/prod/v0'
:'https://api.core.quikvz.com/v0'
exports.contact = '/contact'
exports.form = '/form'
exports.lm = "/lm";
exports.log = "/logs";
exports.metrics = "/metrics";

