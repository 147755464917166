import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Services from "./pages/Services/Services";
import Portfolio from "./pages/Portfolio/Portfolio";
import Contact from "./pages/Contact/Contact";
import Providers from "./pages/Providers/Providers";
import ProjectDetails from "./pages/Project/ProjectDetails";

const Routers = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/providers" element={<Providers />} />
          <Route path="/project/:projectId" element={<ProjectDetails />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default Routers