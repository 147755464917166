import RootLayout from '../../ui-components/RootLayout'
import { useParams } from 'react-router-dom'
import ProjectDetailsData from "../../data/ProjectDetails.json"
import ProjectDetailsSec from '../../ui-components/projectDetails/ProjectDetailsSec'

type sections = {
  heading: string,
  content: string
}

type images = {
  image_url: string,
  caption: string
}

type DetailsProps = {
  title: string,
  date: string,
  author: string,
  introduction: string,
  sections: sections[],
  images: images[],
  tags: string[],
  call_to_action: string,
}


const ProjectDetails = () => {

  const projectId = useParams().projectId || "";

  return (
    <>
      <RootLayout>
        <main className='pt-20 mx-auto max-w-7xl p-6 lg:px-8'>
          <ProjectDetailsSec projectId={projectId} projects={ProjectDetailsData} />
        </main>
      </RootLayout>
    </>

  )
}

export default ProjectDetails