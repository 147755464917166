
type footerAboutProps = {
  aboutData: {
    Header: string,
    Description: string,
    Logo: {
      White: string,
      Black: string,
      AltText: string,
      Link: string,
    }
  }
}

const FooterAbout = (props: footerAboutProps) => {

  const { Description, Logo } = props.aboutData;

  return (
    <>
      <a href={Logo.Link} className='mb-5 block'>
        <img className='h-10 w-10' src={Logo.White} alt={Logo.AltText} />
      </a>
      {/* <h4 className='text-gray-400 font-semibold text-lg mb-4'>{Header}</h4> */}
      <p className='w-full text-gray-200 text-sm'>{Description}</p>
    </>
  )
}

export default FooterAbout