import React, { useState, useContext } from 'react'
import RootLayout from '../../ui-components/RootLayout'
import servicesData from "../../data/Services.json";
import ServiceContent from '../../ui-components/services/ServiceContent';
import { Link } from 'react-router-dom';
import CTA from '../../ui-components/cta/CTA';
import { AppContext } from '../../context/AppContext';

const Services = () => {

  const [servicesContent, setServicesContent] = useState(servicesData);
  const [selectedService, setSelectedService] = useState("Infrastructure Monitoring");
  let [isOpen, setIsOpen] = useState(false);
  const { dark } = useContext(AppContext);

  const selectService = (service: string) => {
    setSelectedService(service);
  }

  function openModal() {
    setIsOpen(true)
  }

  const handleServiceClick = (el: string) => {
    openModal();
    selectService(el)
  }

  return (
    <>
      <RootLayout>
        <section className='pt-20 mx-auto max-w-7xl p-6 lg:px-8'>
          <h1 className={`relative font-display ${dark ? 'text-white' : ''} text-6xl flex justify-center font-semibold`}>Services
            <span className='abstraction-heading md:text-7xl text-6xl text-emerald-50 uppercase'>Services</span>
          </h1>
          <div className='mt-10 flex md:flex-row flex-col gap-24'>
            <div className='flex-1 overflow-auto' style={{ height: '500px' }}>
              <ul className='pr-5'>
                {
                  Object.keys(servicesContent.Services || []).map(el => (
                    <li >
                      <Link className={`block p-2 cursor-pointer rounded-lg mb-4 transition text-lg ${selectedService === el ? 'bg-emerald-300 text-zinc-50' : 'bg-zinc-100'} `} onClick={() => handleServiceClick(el)} to={`#${selectedService}`} dangerouslySetInnerHTML={{ __html: el }} />
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className='flex-1'>
              <ServiceContent isOpen={isOpen} setIsOpen={setIsOpen} selectedService={selectedService} servicesContent={servicesContent.Services} />
            </div>
          </div>
        </section>
        <CTA />
      </RootLayout>
    </>
  )
}

export default Services