import { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import axios from "axios";
import { coreEndpoint, contact, form } from "../../constants/core/endpoints";

const ContactForm = () => {
  const { dark } = useContext(AppContext);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendMessage = async (event: { preventDefault: () => void }) => {
    console.log(formData);
    event.preventDefault();
    try {
      const response = await axios.post(
        `${coreEndpoint}${contact}${form}`,
        formData
      );

      // Handle success
      alert("Message sent successfully");
      console.log("Data:", response.data);

      // Reset the form
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      // Handle error
      alert("An error occurred while sending the message.");
      console.error("There was an error sending the message", error);
    }
  };

  return (
    <div>
      <form onSubmit={sendMessage}>
        <div className="flex flex-col md:flex-row gap-5 mb-5">
          <div className="flex-1">
            <label
              className={`${dark ? "text-white" : ""} block text-sm mb-2`}
              htmlFor="name"
            >
              Name
            </label>
            <input
              name="name"
              value={formData.name}
              onChange={handleChange}
              autoFocus={true}
              placeholder="Enter your full name"
              className="focus:ring-2 ring-emerald-300 ring-offset-2 outline-none w-full p-2 rounded-lg border-2 border-gray-600"
              id="name"
              type="text"
              required
            />
          </div>
          <div className="flex-1">
            <label
              className={`${dark ? "text-white" : ""} block text-sm mb-2`}
              htmlFor="email"
            >
              Email
            </label>
            <input
              name="email"
              value={formData.email}
              onChange={handleChange}
              
              placeholder="Enter your email address"
              className="w-full p-2 rounded-lg focus:ring-2 ring-emerald-300 ring-offset-2 outline-none border-2 border-gray-600"
              id="email"
              type="email"
              required
            />
          </div>
        </div>
        <div className="mb-5">
          <label
            className={`${dark ? "text-white" : ""} block text-sm mb-2`}
            htmlFor="subject"
          >
            Subject
          </label>
          <input
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            placeholder="What's this about?"
            className="focus:ring-2 ring-emerald-300 ring-offset-2 outline-none w-full p-2 rounded-lg border-2 border-gray-600"
            id="subject"
            type="text"
            required
          />
        </div>
        <div>
          <label
            className={`${dark ? "text-white" : ""} block mb-2`}
            htmlFor="message"
          >
            Message
          </label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="focus:ring-2 ring-emerald-300 ring-offset-2 outline-none w-full p-2 rounded-lg border-2 border-gray-600"
            id="message"
            rows={5}
            required
            placeholder="Tell us more..."
          />
        </div>
        <button
          type="submit"
          className={`${
            dark ? "bg-white text-gray-900" : "bg-gray-900 text-white"
          } mt-2 p-2 rounded-lg w-full`}
        >
          Send Message
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
