type footerSocialMediaProps = {
  socialMedia: {
    Header: string,
    Icons: { Platform: string, Link: string, Icon: string }[]
  }
}

const FooterSocialMedia = (props: footerSocialMediaProps) => {

  const { Header, Icons } = props.socialMedia

  return (
    <>
      <h4 className='text-gray-300 font-normal text-md mb-4'>{Header}</h4>
      <ul>
        {
          Icons.map(icon => (
            <li key={icon.Platform} className='mb-2 flex items-center gap-3 hover:opacity-80'>
              <img className='w-6' src={icon.Icon} alt={icon.Platform} />
              <a className='text-white text-sm' target='_blank' href={icon.Link} rel="noreferrer">{icon.Platform}</a>
            </li>
          ))
        }
      </ul>
    </>
  )
}

export default FooterSocialMedia