import React, { useState } from 'react'
import FooterAbout from './FooterAbout'
import footerContent from "../../data/Footer.json";
import FooterQuickLinks from './FooterQuickLinks';
import FooterContact from './FooterContact';
import FooterSocialMedia from './FooterSocialMedia';

const Footer = () => {

  const [footerData, setFooterData] = useState(footerContent.Footer)

  return (
    <footer className="mx-auto max-w-7xl p-6 lg:px-8 bg-gray-800">
      <div className='flex items-start justify-between md:flex-row flex-col'>
        <div aria-label="about the company" className='mb-6 md:mb-0 md:w-2/6 w-full lg:mr-28 mr-0'>
          <FooterAbout aboutData={footerData.AboutSection} />
        </div>
        <div aria-label='quick links' className='mr-10 mb-6 md:mb-0'>
          <FooterQuickLinks quickLinks={footerData.LinksSection} />
        </div>
        <div aria-label='contacts' className='mr-10 mb-6 md:mb-0'>
          <FooterContact contact={footerData.ContactSection} />
        </div>
        <div aria-label='social media' className='flex-1'>
          <FooterSocialMedia socialMedia={footerData.SocialMediaSection} />
        </div>
      </div>
    </footer>
  )
}

export default Footer