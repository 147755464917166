import React from 'react'
import Header from './header/Header'
import Footer from './footer/Footer'

interface RLProps {
  children: React.ReactNode
}

const RootLayout = (props: RLProps) => {

  const { children } = props

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  )
}

export default RootLayout