import React, { useContext, useState } from 'react'
import RootLayout from '../../ui-components/RootLayout'
import { AppContext } from '../../context/AppContext';
import contactData from "../../data/contact.json"
import ContactForm from '../../ui-components/contact/ContactForm';
import { ClockIcon, EnvelopeIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/outline';

const Contact = () => {

  const { dark } = useContext(AppContext);
  const [contactContent, setContactContent] = useState(contactData.ContactUsPage);


  return (
    <div>
      <RootLayout>
        <section className='pt-20 mx-auto max-w-7xl p-6 lg:px-8 flex md:flex-row flex-col gap-20 flex-start'>
          <div className='flex-1'>
            <h1 className={`${dark ? 'text-white' : ''} font-display md:w-3/4 w-full text-5xl font-semibold`}>{contactContent.Header}</h1>
            <p className={`${dark ? 'text-gray-50' : 'text-gray-700'} text-md md:w-4/5 w-full`}>{contactContent.SubHeader}</p>
            <div className='mt-5'>
              <ul>
                <li className='mb-2 flex gap-3 items-center'>
                  <MapPinIcon className={`${dark ? 'text-white' : 'text-gray-900'}  w-5 h-5`} />
                  <span className={`${dark ? 'text-white' : ''}`}>{contactContent.Address}</span>
                </li>

                <li className='mb-2 flex gap-3 items-center'>
                  <PhoneIcon className={`${dark ? 'text-white' : 'text-gray-900'}  w-5 h-5`} />
                  <a className={`${dark ? 'text-white' : ''}`} href={`tel:${contactContent.PhoneNumber}`}>{contactContent.PhoneNumber}</a>
                </li>

                <li className='mb-2 flex gap-3 items-center'>
                  <EnvelopeIcon className={`${dark ? 'text-white' : 'text-gray-900'}  w-5 h-5`} />
                  <a className={`${dark ? 'text-white' : ''}`} href={`mailto:${contactContent.Email}`}>{contactContent.Email}</a>
                </li>

                <li className='mb-2 flex gap-3 items-center'>
                  <ClockIcon className={`${dark ? 'text-white' : 'text-gray-900'} text-gray-900 w-5 h-5`} />
                  <span className={`${dark ? 'text-white' : ''}`}>{contactContent.OpeningHours}</span>
                </li>
              </ul>
            </div>
            <div>
              <ul className='flex gap-3 mt-5'>
                {
                  contactContent.SocialMedia.map(icon => (
                    <li key={icon.Platform} className='mb-2 flex items-center gap-3 hover:opacity-80'>
                      {
                        <a href={icon.Link}>
                          <img className='w-6' src={icon.Icon} alt={icon.Platform} />
                        </a>
                      }
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          <div className='flex-1'>
            <ContactForm />
          </div>
        </section>
      </RootLayout>
    </div>
  )
}

export default Contact