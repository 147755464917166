import React, { useContext, useState } from "react";
import { PrimaryBtnLink } from "../Buttons";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import heroData from "../../data/Hero.json";
import { AppContext } from "../../context/AppContext";

const Hero = () => {
  const [heroContent, setHeroContent] = useState(heroData);
  const { dark } = useContext(AppContext);
  const bgStyles = {
    background: 'url("/assets/abs-lines.png")',
    backgroundPosition: "top",
    backgroundSize: "cover",
  };

  return (
    <section
      style={bgStyles}
      id="hero"
      className="hero-section pt-32 mx-auto max-w-7xl p-6 lg:px-8"
    >
      <div className="hero-wrapper flex flex-col gap-6 md:flex-row items-center justify-between">
        <div className="flex-1">
          <small
            className={`${dark ? "bg-white text-gray-900" : "text-white"
              } px-4 text-xs bg-gray-800 rounded-md uppercase mb-3 block w-max`}
          >
            {heroContent.SubHeading}
          </small>
          <h1
            className={`${dark ? "text-white" : ""
              } text-5xl font-display font-bold`}
            dangerouslySetInnerHTML={{ __html: heroContent.Heading }}
          />
          <p
            className={`${dark ? "text-gray-50" : "text-gray-800"
              } text-md mt-2 font-md`}
          >
            {heroContent.Description}
          </p>
          <PrimaryBtnLink
            to={heroContent.BtnLink}
            text={heroContent.BtnLabel}
            className={`${dark ? "bg-white text-gray-900" : "text-white"
              } mt-4 px-4 py-3`}
            hasIcon={true}
            icon={
              <ArrowRightCircleIcon
                className={`${dark ? "text-gray-900" : "text-white"} w-5 h-5`}
              />
            }
          />
        </div>
        <div className="flex-1 flex justify-end w-full">
          <img
            className="rounded-3xl w-full h-full block object-cover"
            src={heroContent.Image}
            alt={heroContent.AltText}
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
