import React, { useContext, useEffect, useState } from 'react'
import ProjectImageSlider from './ProjectImageSlider'
import { ClockIcon, UserIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'

type sections = {
  heading: string,
  content: string
}

type images = {
  image_url: string,
  caption: string
}

type ProjectDetailsProps = {
  projects: {
    [key: string]: {
      title: string,
      date: string,
      author: string,
      introduction: string,
      sections: sections[],
      images: images[],
      tags: string[],
      call_to_action: string,
    }
  },
  projectId: string
}

type DetailsProps = {
  title: string,
  date: string,
  author: string,
  introduction: string,
  sections: sections[],
  images: images[],
  tags: string[],
  call_to_action: string,
}


const ProjectDetailsSec: React.FC<ProjectDetailsProps> = (props) => {

  const DetailsValues = {
    title: "",
    date: "",
    author: "",
    introduction: "",
    sections: [],
    images: [],
    tags: [],
    call_to_action: "",
  }

  const [details, setDetails] = useState<DetailsProps>(DetailsValues);
  const { dark } = useContext(AppContext);

  const { projects, projectId } = props;

  useEffect(() => {

    const details = projects[projectId];
    setDetails(details)

  }, [projectId, projects])

  return (
    <>
      <ProjectImageSlider images={details?.images} />
      <div className='md:w-4/5 w-full	m-auto'>
        <h1 className={`${dark ? 'text-white' : 'text-gray-900'} mt-10 text-4xl font-display font-semibold`}>{details.title}</h1>
        <div className='author flex items-center gap-8 mt-10'>
          <div className={`${dark ? 'text-white' : 'text-gray-900'} flex items-center gap-2`}>
            <ClockIcon aria-hidden="true" className={`${dark ? 'text-white' : 'text-gray-900'} w-5 h-5`} />
            <span>{details.date}</span>
          </div>
          <div className={`${dark ? 'text-white' : 'text-gray-900'} flex items-center gap-2`}>
            <UserIcon aria-hidden="true" className={`${dark ? 'text-white' : 'text-gray-900'} w-5 h-5 text-gray-900`} />
            <span>{details.author}</span>
          </div>
        </div>
        <div className='introduction mt-2 p-4 italic bg-slate-100 rounded-xl shadow-lg md:w-8/12 w-full'>
          {details.introduction}
        </div>
        <div className={`${dark ? 'text-white' : 'text-gray-900'} sections mt-10`}>
          {
            details.sections.map(section => (
              <div key={section.heading} className='mb-5'>
                <h4 className='mb-2 text-xl font-semibold'>{section.heading}</h4>
                <p>{section.content}</p>
              </div>
            ))
          }
        </div>
        <div className='mt-5 flex flex-wrap items-center gap-3'>
          {
            details.tags.map(tag => (
              <span key={tag} className='text-xs bg-emerald-300 text-emerald-900 rounded-lg p-2'>
                #{tag}
              </span>
            ))
          }
        </div>
        <div className='call-to-action mt-5 flex flex-wrap items-center gap-2'>
          <p className={`${dark ? 'text-white' : 'text-gray-900'} text-gray-900`}>{details.call_to_action}</p><Link className={`${dark ? 'text-white' : 'text-gray-900'} underline`} to="/contact">Contact us</Link>
        </div>
      </div>
    </>
  )
}

export default ProjectDetailsSec