import { EnvelopeIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/outline'

type footerQuickLinksProps = {
  contact: {
    Header: string,
    Address: string,
    PhoneNumber: string,
    Email: string
  }
}


const FooterContact = (props: footerQuickLinksProps) => {

  const { Header, Address, Email, PhoneNumber } = props.contact

  return (
    <>
      <h4 className='text-gray-300 font-normal text-md mb-4'>{Header}</h4>
      <ul>
        <li className='mb-2 flex items-center gap-2'>
          <MapPinIcon aria-hidden="true" className='w-4 h-4 text-emerald-300' />
          <p className='text-white text-sm'>{Address}</p>
        </li>
        <li className='mb-2 flex items-center gap-2'>
          <PhoneIcon aria-hidden="true" className='w-4 h-4 text-emerald-300' />
          <a className='text-white text-sm hover:opacity-70 underline' href={`tel:${PhoneNumber}`}>{PhoneNumber}</a>
        </li>
        <li className='mb-2 flex items-center gap-2'>
          <EnvelopeIcon aria-hidden="true" className='w-4 h-4 text-emerald-300' />
          <a className='text-white text-sm hover:opacity-70 underline' href={`mailto:${Email}`}>{Email}</a>
        </li>
      </ul>
    </>
  )
}

export default FooterContact