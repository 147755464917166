import React, { useState, useContext } from 'react'
import ServiceCard from './ServiceCard'
import servicesData from "../../data/Services.json";
import { AppContext } from '../../context/AppContext';



const ServicesSection = () => {

  const [servicesContent, setServicesContent] = useState(servicesData);
  const { dark } = useContext(AppContext);
  const bgStyles = { background: dark ? 'transparent' : 'url("/assets/abs-lines-7.png")', backgroundPosition: '120% 50%', backgroundSize: '60%', backgroundRepeat: "no-repeat" }

  return (
    <section style={bgStyles} className='pt-24 pb-24 mx-auto max-w-7xl p-6 lg:px-8'>
      <h2 className={`relative ${dark ? 'text-white' : ''} font-display text-4xl flex justify-center font-semibold`}>Our Services
        <span className='abstraction-heading md:text-6xl text-5xl text-zinc-50 uppercase'>Our Services</span>
      </h2>
      <div className='services-wrapper pt-20'>
        <ServiceCard servicesContent={servicesContent} />
      </div>
    </section>
  )
}

export default ServicesSection