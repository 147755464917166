import React, { useState, useContext } from 'react'
import RootLayout from '../../ui-components/RootLayout'
import providersData from "../../data/ProvidersSlider.json";
import Provider from '../../ui-components/providers/Provider';
import { AppContext } from '../../context/AppContext';
import Client from '../../ui-components/clients/Client';
import CTA from '../../ui-components/cta/CTA';

const bgStyles = { background: 'url("/assets/abs-lines2.png")', backgroundPosition: '120% 50%', backgroundSize: '30%', backgroundRepeat: "no-repeat" }

const Providers = () => {

  const [providersContent, setProvidersContent] = useState(providersData.ProvidersSlider)
  const { dark } = useContext(AppContext);

  return (
    <>
      <RootLayout>
        <section style={bgStyles} className='pt-20 mx-auto max-w-7xl p-6 lg:px-8 flex md:flex-row flex-col items-center gap-10' >
          <div className='flex-1'>
            <h1 className={`${dark ? 'text-white' : ''} font-display md:w-3/4 w-full text-6xl font-semibold`}>{providersContent.Header}</h1>
            <p className={`${dark ? 'text-gray-50' : 'text-gray-700'} text-lg md:w-4/5 w-full`}>{providersContent.SubHeader}</p>
            <p className={`${dark ? 'text-gray-50' : 'text-gray-700'} mt-2 md:text-sm text-md w-4/5`}>{providersContent.Introduction}</p>
          </div>
          <div className='flex-1'>
            <img src="/assets/lps1.jpg" className='w-full rounded-xl' alt={providersContent.Header} />
          </div>
        </section>
        <section aria-label="providers" id='providers' className='mx-auto max-w-7xl p-6 lg:px-8 flex md:flex-row flex-col gap-10 items-start justify-center providers-section'>
          {
            Object.keys(providersContent.Providers).map(provider => (
              <Provider providers={providersContent.Providers} provider={provider} />
            ))
          }
        </section>
        <section className='mx-auto max-w-7xl py-24 px-6 lg:px-8 flex justify-center flex-col'>
          <p className={`${dark ? 'text-gray-50' : 'text-gray-700'} text-md md:w-1/2 w-full pb-5 m-auto text-center`}>{providersContent.Conclusion}</p>
          <p className={`${dark ? 'text-gray-50' : 'text-gray-700'} text-md md:w-1/2 w-full m-auto text-center`}>{providersContent.FooterNote}</p>
        </section>
        <Client />
        <CTA />
      </RootLayout>
    </>
  )
}

export default Providers