import React from 'react'
import { WhitePrimaryBtn } from '../Buttons'

const bgStyles = { background: 'linear-gradient(90deg, rgba(28,26,52,1) 0%, rgba(28,26,52,0.30) 100%), url("/assets/lps1.jpg")', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: "no-repeat" }

type SlideProps = {
  slide: {
    Header: string,
    SubHeader: string,
    BackgroundImage: string,
    CTA: {
      Text: string,
      Link: string
    }
  }
}

const Slide: React.FC<SlideProps> = (props) => {

  const { slide } = props;

  return (
    <div style={{ background: `linear-gradient(90deg, rgba(28,26,52,1) 0%, rgba(28,26,52,0.30) 100%), url(${slide.BackgroundImage})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: "no-repeat" }} className='p-10 flex flex-col justify-center w-full h-96 rounded-3xl'>
      <h2 className='md:w-2/5 w-full md:text-5xl text-3xl font-semibold font-display text-white'>
        {slide.Header}
      </h2>
      <p className='text-white md:w-1/3 w-full'>
        {slide.SubHeader}
      </p>
      <WhitePrimaryBtn className='mt-10' text={slide.CTA.Text} to={slide.CTA.Link} />
    </div>
  )
}

export default Slide