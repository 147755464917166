import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/css';

type images = {
  image_url: string,
  caption: string
}

type ImagesProps = {
  images: images[]
}

const ProjectImageSlider: React.FC<ImagesProps> = (props) => {

  const { images } = props;
  SwiperCore.use([Autoplay])

  return (
    <>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
      >
        {
          images.map(image => (
            <SwiperSlide key={image.image_url}>
              <div style={{
                background: `url(${image.image_url})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover"
              }} className='w-full h-96 rounded-2xl'>
                <div className='flex items-center justify-center h-full'>
                  <h3 className='flex items-center justify-center md:text-2xl text-md p-3 bg-gray-900 rounded-xl text-white font-display font-semibold'>{image.caption}</h3>
                </div>
              </div>
            </SwiperSlide>
          ))
        }
      </Swiper >
    </>
  )
}

export default ProjectImageSlider