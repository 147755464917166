import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import lpsContent from "../../data/LandingPageSlider.json";
import 'swiper/css';
import Slide from './Slide';

const LandingPageSlider = () => {

  const [lpsData, setLpsData] = useState(lpsContent.LandingPageSlider);
  SwiperCore.use([Autoplay])

  return (
    <section className='md:pt-24 pt-8 mx-auto max-w-7xl p-6 lg:px-8'>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
      >
        {
          lpsData.map(slide => (
            <SwiperSlide>
              <Slide slide={slide} />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </section>
  )
}

export default LandingPageSlider