import React from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

type PortfolioProp = {
  portfolio: {
    Title: string,
    Description: string,
    Platform: string,
    ImageLink: string,
    CaseStudyLink: string
  }
}

const PortfolioCard: React.FC<PortfolioProp> = (props) => {

  const { portfolio } = props;

  return (
    <Link target='_blank' to={portfolio.CaseStudyLink} className='cursor-pointer hover:shadow-lg hover:-translate-y-2 transition bg-white p-2 rounded-lg'>
      <div className='w-full h-52'>
        <LazyLoadImage
          alt={portfolio.Title}
          effect="blur"
          width="100%"
          height="100%"
          src={portfolio.ImageLink} className='w-full h-full object-cover block rounded-xl' />
      </div>
      <div className='p-2'>
        <h2 className='font-display text-xl font-semibold mt-2 text-gray-800'>
          {portfolio.Title}
        </h2>
        <p className='text-sm text-gray-700 leading-5 mt-2'>
          {portfolio.Description}
        </p>
        <span className='block mt-3 font-semibold text-sm'>Platform: <span className='text-xs p-2 bg-zinc-100 rounded-lg'>{portfolio.Platform}</span></span>
      </div>
    </Link>
  )
}

export default PortfolioCard