import { useContext } from 'react';
import Routers from './Routers';
import { AppContext } from './context/AppContext';
import ThemeProvider from './context/ThemeProvider';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBU2yuMian4LSOzL4q-qqfo_RIJ05iKqYs",
  authDomain: "quikvz.firebaseapp.com",
  projectId: "quikvz",
  storageBucket: "quikvz.appspot.com",
  messagingSenderId: "989694596091",
  appId: "1:989694596091:web:30c344ae70a40aea89315e",
  measurementId: "G-SW7P0X4BRV"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

function App() {

  const { dark } = useContext(AppContext);

  return (
    <div className={`App ${dark ? 'bg-gray-900' : 'bg-white'}`} id="app">
      <ThemeProvider>
        <Routers />
      </ThemeProvider>
    </div>
  );
}

export default App;
