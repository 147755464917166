import React, { useState } from 'react'
import ctaData from "../../data/Call-to-action.json"
import { WhitePrimaryBtn } from '../Buttons'

const bgStyle = {
  background: "linear-gradient(45deg,rgba(31,41,55,1), #1f2937a6), url('/assets/cta-bg.webp')",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover"
}

const CTA = () => {

  const [ctaContent, setCtaContent] = useState(ctaData)

  return (
    <section className='mx-auto max-w-7xl p-6 lg:px-8'>
      <div style={bgStyle} aria-label='call to action' className='cta-section flex flex-col items-center md:items-start w-full bg-gray-800 rounded-2xl p-10 relative'>
        <h2 className='text-center md:text-left text-4xl uppercase text-white font-display'>{ctaContent.Header}</h2>
        <p className='text-center md:text-left text-gray-100'>{ctaContent.Description}</p>
        <WhitePrimaryBtn to="/contact" className='mt-4' text={ctaContent['btn-label']} />
      </div>
    </section>
  )
}

export default CTA