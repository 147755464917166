import React, { FC, useState } from 'react'
import { AppContext } from './AppContext';

interface TPProps {
  children: React.ReactNode
}

const ThemeProvider = (props: TPProps) => {

  const { children } = props;

  const [dark, setDark] = useState(false);

  const toggleDark = () => {
    setDark(!dark);
  };

  return (
    <AppContext.Provider value={{ dark, toggleDark }}>
      {children}
    </AppContext.Provider>
  )
}

export default ThemeProvider