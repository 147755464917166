import React, { useState, useEffect, useContext } from 'react'
import { PrimaryBtnLink } from '../Buttons'
import { ArrowRightCircleIcon } from '@heroicons/react/24/outline'
import { AppContext } from '../../context/AppContext'


interface ServicesProps {
  servicesContent: {
    Image: {
      src: string,
      alt: string
    },
    Services: {
      [key: string]: {
        Image: string,
        List: {
          [key: string]: string
        }
      }
    }
  }
}

const ServiceCard: React.FC<ServicesProps> = (props) => {

  const { servicesContent } = props;

  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [serviceTitle, setServiceTitle] = useState<string>("");
  const { dark } = useContext(AppContext);

  useEffect(() => {
    const service: string = Object.keys(servicesContent.Services)[slideIndex];
    setServiceTitle(service)
  }, [])

  useEffect(() => {
    const autoSlide = setTimeout(() => {
      if (slideIndex === Object.keys(servicesContent.Services).length - 1) {
        setSlideIndex(0)
        const service: string = Object.keys(servicesContent.Services)[0];
        setServiceTitle(service)
      } else {
        setSlideIndex(slideIndex + 1)
        const service: string = Object.keys(servicesContent.Services)[slideIndex + 1];
        setServiceTitle(service)
      }

    }, 4000)

    return () => {
      clearTimeout(autoSlide)
    }
  }, [slideIndex])

  const handleClick = (index: number) => {
    const service: string = Object.keys(servicesContent.Services)[index];
    setServiceTitle(service)
    setSlideIndex(index)
  }

  return (
    <div className='service-section-left flex md:flex-row flex-col items-center gap-20 mb-2'>
      <div className='service-gallery flex-1'>
        <img src={servicesContent.Services[serviceTitle]?.Image} alt={servicesContent.Image.alt} className='w-full h-full object-cover block rounded-2xl shadow-2xl' />
      </div>
      <div className='service-section-right flex-1 relative'>
        <h3 className={`md:ml-0 mb-5 -mt-32 md:mt-0 ml-10 mr-10 bg-white md:w-max shadow-2xl rounded-xl p-5 font-bold font-display font-medium text-3xl text-zinc-800`} dangerouslySetInnerHTML={{ __html: serviceTitle }} />
        <ul>
          {
            Object.keys(servicesContent.Services[serviceTitle]?.List || []).map((el) => (
              <li className='mb-5' key={el}>
                <h4 className={`${dark ? 'text-white' : ''} font-semibold mb-2`}>{el}</h4>
                <p className={`${dark ? 'text-white' : ''} text-sm md:w-8/12 w-full`}>{servicesContent.Services[serviceTitle].List[el]}</p>
              </li>
            ))
          }
        </ul>
        <PrimaryBtnLink to="/hello" text='Discover' className={`${dark ? 'bg-white text-gray-900 hover:bg-gray-50' : 'text-white hover:bg-gray-800'} mt-4 px-4 py-3`} hasIcon={true} icon={<ArrowRightCircleIcon className={`${dark ? 'text-gray-900' : 'text-white'} w-5 h-5`} />} />
        <div aria-label='services slider controllers' className='mt-10 w-full flex items-center justify-center md:justify-start gap-3 absolute md:bottom-2 -bottom-10 left-0'>
          {
            Array.from({ length: Object.keys(servicesContent.Services || [])?.length }).map((_, index) => (
              <div key={index} onClick={() => handleClick(index)} className={`w-5 h-1 ${index === slideIndex ? "bg-emerald-300" : "bg-gray-700"} rounded-xs cursor-pointer hover:bg-emerald-300`} aria-hidden="true"></div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default ServiceCard