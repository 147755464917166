import React, { useContext, useState } from 'react';
import processData from "../../data/Process.json"
import { AppContext } from '../../context/AppContext';
import ProcessStep from './ProcessStep';

const Process = () => {

  const [processContent, setProcessContent] = useState(processData);
  const { dark } = useContext(AppContext);

  return (
    <section className='pt-10 mx-auto max-w-7xl p-6 lg:px-8'>
      <h1 className={`${dark ? 'text-white' : ''} relative font-display text-6xl flex justify-center font-semibold`}>{processContent.header}
      </h1>
      <div className='mt-20 grid md:grid-cols-3 grid-cols-1 items-start gap-10 justify-center'>
        {
          processContent.process.map(process => (
            <ProcessStep step={process} />
          ))
        }
      </div>
    </section>
  )
}

export default Process