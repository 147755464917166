import { Link } from 'react-router-dom';

type footerQuickLinksProps = {
  quickLinks: {
    Header: string,
    Items: { Text: string; Link: string; }[]
  }
}

const FooterQuickLinks = (props: footerQuickLinksProps) => {

  const { Header, Items } = props.quickLinks

  return (
    <>
      <h4 className='text-gray-300 font-normal text-md mb-4'>{Header}</h4>
      <ul>
        {
          Items.map(item => (
            <li key={item.Link} className='mb-2'>
              <Link to={item.Link} className="hover:text-emerald-300 text-sm leading-6 text-gray-50">{item.Text}</Link>
            </li>
          ))
        }
      </ul>
    </>
  )
}

export default FooterQuickLinks