import { createContext } from "react";

interface ThemeContextType {
  dark: boolean;
  toggleDark?: () => void;
};

const defaultState = {
  dark: false,
}

export const AppContext = createContext<ThemeContextType>(defaultState);