import React from 'react'
import RootLayout from '../../ui-components/RootLayout'
import Hero from '../../ui-components/hero/Hero'
import ServicesSection from '../../ui-components/services/ServicesSection'
import CTA from '../../ui-components/cta/CTA'
import LandingPageSlider from '../../ui-components/lpslider/LandingPageSlider'
import Process from '../../ui-components/process/Process'

const Home = () => {
  return (
    <>
      <RootLayout>
        <Hero />
        <LandingPageSlider />
        <ServicesSection />
        <Process />
        <CTA />
      </RootLayout>
    </>
  )
}

export default Home