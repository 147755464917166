import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import { Link, LinkProps } from 'react-router-dom';

interface BtnProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string,
  className?: string,
  hasIcon?: boolean,
  icon?: ReactNode,
  link?: string
}

interface CustomLinkProps extends LinkProps {
  text: string,
  className?: string,
  hasIcon?: boolean,
  icon?: ReactNode,
}

interface CustomSubmitProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string,
  className?: string,
  hasIcon?: boolean,
  icon?: ReactNode,
}


export const WhiteBorderBtn = (props: BtnProps) => {

  const { type, text, className, hasIcon = false, icon } = props;

  const flex = hasIcon ? 'flex items-center gap-3' : ""

  return (
    <button type={type} className={`${flex} p-2 text-sm rounded-lg border-2 border-white text-gray-50 active:bg-zinc-200 hover:text-gray-900 hover:bg-white transition font-semibold ${className} `}>
      {text}
      {
        hasIcon ?
          <span>{icon}</span> : null
      }
    </button>
  )
}

export const WhiteBorderLink = (props: CustomLinkProps) => {

  const { text, className, hasIcon = false, icon, ...rest } = props;

  const flex = hasIcon ? 'flex items-center gap-3' : ""

  return (
    <Link {...rest} className={`${flex} p-2 text-sm rounded-lg border-2 border-white text-gray-50 active:bg-zinc-200 hover:text-gray-900 hover:bg-white transition font-semibold ${className} `}>
      {text}
      {
        hasIcon ?
          <span>{icon}</span> : null
      }
    </Link>
  )
}

export const PrimaryBtnLink: React.FC<CustomLinkProps> = ({ text, className, hasIcon = false, icon, ...rest }) => {

  const flex = hasIcon ? 'flex items-center gap-3' : ""

  return (
    <Link {...rest} className={`${flex} w-max p-2 text-sm rounded-lg bg-gray-900 active:bg-zinc-200 transition font-semibold ${className} `}>
      {text}
      {
        hasIcon ?
          <span>{icon}</span> : null
      }
    </Link>
  )
}

export const WhitePrimaryBtn: React.FC<CustomLinkProps> = ({ text, className, hasIcon = false, icon, ...rest }) => {

  const flex = hasIcon ? 'flex items-center gap-3' : ""

  return (
    <Link {...rest} className={`${flex} block w-max p-2 text-sm rounded-lg bg-white text-gray-900 active:bg-zinc-200 hover:bg-gray-200 transition font-semibold ${className} `}>
      {text}
      {
        hasIcon ?
          <span>{icon}</span> : null
      }
    </Link>
  )
}

export const WhitePrimarySubmit: React.FC<CustomSubmitProps> = ({ text, className, hasIcon = false, icon, ...rest }) => {

  const flex = hasIcon ? 'flex items-center gap-3' : ""

  return (
    <button {...rest} type='submit' className={`${flex} block w-max p-2 text-sm rounded-lg bg-white text-gray-900 active:bg-zinc-200 hover:bg-gray-200 transition font-semibold ${className} `}>
      {text}
      {
        hasIcon ?
          <span>{icon}</span> : null
      }
    </button>
  )
}

export const BlackPrimarySubmit: React.FC<CustomSubmitProps> = ({ text, className, hasIcon = false, icon, ...rest }) => {

  const flex = hasIcon ? 'flex items-center gap-3' : ""

  return (
    <button {...rest} type='submit' className={`${flex} block w-max p-2 text-sm rounded-lg bg-gray-900 text-gray-50 active:bg-zinc-200 hover:bg-gray-200 transition font-semibold ${className} `}>
      {text}
      {
        hasIcon ?
          <span>{icon}</span> : null
      }
    </button>
  )
}