import { useState, useContext, useEffect } from 'react'
import {
  Bars3Icon, MoonIcon, SunIcon,
} from '@heroicons/react/24/outline'
// import {
//   Bars3Icon, MagnifyingGlassIcon, MoonIcon, SunIcon, UserCircleIcon,
// } from '@heroicons/react/24/outline'
import MobileHeader from './MobileHeader'
import headerData from "../../data/Navbar.json"
import { WhiteBorderLink } from '../Buttons'
import { NavLink, Link } from "react-router-dom";
import Search from '../Search/Search'
import { AppContext } from '../../context/AppContext'
import { useLocation } from 'react-router-dom';



const Header = () => {

  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [headerContent, setHeaderContent] = useState(headerData.Navbar);
  let [isSearchOpen, setIsSearchOpen] = useState(false);
  const { dark, toggleDark } = useContext(AppContext);

  const location = useLocation();

  function openModal() {
    setIsSearchOpen(true)
  }


  const handleTheme = () => {
    toggleDark!()
  }

  useEffect(() => {

    const doc = document.getElementById("app");
    if (doc) {
      doc.style.backgroundColor = dark ? "#111827" : "white"
    }

  }, [dark])


  return (
    <>
      <header className="main-navbar backdrop-blur-lg bg-gray-800/90 rounded-2xl z-20">
        <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 py-4 lg:px-8" aria-label="Global">
          <div className='flex gap-24 justify-between lg:justify-normal w-full lg:w-fit w-full'>
            <div className="flex lg:flex-1">
              <Link to={headerContent.Logo.Link} className="-m-1.5 p-1.5 text-xl text-white font-bold">
                <img className='header-logo h-10 w-10' src={headerContent.Logo.White} alt={headerContent.Logo.AltText} />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6 text-gray-50" aria-hidden="true" />
              </button>
            </div>
            <ul className="hidden lg:flex lg:gap-x-12 items-center">
              {/* "hover:text-emerald-300 text-sm font-semibold leading-6 text-gray-50" */}
              {
                headerContent.MenuItems.map(items => (
                  <li key={items.Link}>
                    <NavLink to={items.Link} className={({ isActive, isPending }) => isActive ? "text-emerald-300 text-sm font-semibold leading-6" : "hover:text-emerald-300 text-sm font-semibold leading-6 text-gray-50"}>
                      {items.Text}
                    </NavLink>
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-2 lg:items-center">
            <button onClick={() => handleTheme()} aria-label='search button' className='hover:border border-gray-100 transition active:opacity-70 rounded-lg w-10 h-10 flex items-center justify-center'>
              {
                dark ?
                  <SunIcon aria-hidden="true" className='h-5 w-5 flex-none text-white' /> :
                  <MoonIcon aria-hidden="true" className='h-5 w-5 flex-none text-white' />
              }
            </button>
            {/* <button onClick={() => openModal()} aria-label='search button' className='hover:border border-gray-100 transition active:opacity-70 rounded-lg w-10 h-10 flex items-center justify-center'>
              <MagnifyingGlassIcon aria-hidden="true" className='h-5 w-5 flex-none text-white' />
            </button>
            <button aria-label='profile button' className='hover:border border-gray-100 transition active:opacity-70 rounded-lg w-10 h-10 flex items-center justify-center'>
              <UserCircleIcon aria-hidden="true" className='h-6 w-6 flex-none text-white' />
            </button> */}
            {location.pathname !== "/contact" ? <WhiteBorderLink to={headerContent.CTAButton.Link} text={headerContent.CTAButton.Text} /> : null}
          </div>
        </nav>
        <MobileHeader setMobileMenuOpen={setMobileMenuOpen} mobileMenuOpen={mobileMenuOpen} />
        <Search isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} openModal={openModal} />
      </header>
    </>
  )
}

export default Header