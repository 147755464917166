import { useState, Fragment, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  MagnifyingGlassIcon,
  MoonIcon,
  SunIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import headerData from "../../data/Navbar.json"
import { Link } from 'react-router-dom'
import { BlackPrimarySubmit } from '../Buttons'
import { AppContext } from '../../context/AppContext'


interface MobileHeaderProps {
  mobileMenuOpen: boolean,
  setMobileMenuOpen: (mobileMenuOpen: boolean) => void,
}

const MobileHeader = (props: MobileHeaderProps): JSX.Element => {

  const { mobileMenuOpen, setMobileMenuOpen } = props;
  const [headerContent, setHeaderContent] = useState(headerData.Navbar);
  const { dark, toggleDark } = useContext(AppContext);


  const handleTheme = () => {
    toggleDark!()
  }

  return (
    <>
      <Transition appear show={mobileMenuOpen} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-10" />
            <Dialog.Panel className={`${dark ? 'bg-gray-900' : 'bg-white'} fixed inset-y-0 right-0 z-30 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10`}>
              <div className="flex items-center justify-between">
                <Link to={headerContent.Logo.Link} className="-m-1.5 p-1.5 text-xl text-white font-bold">
                  <img className='header-logo h-10 w-10' src={headerContent.Logo[dark ? 'White' : 'Black']} alt={headerContent.Logo.AltText} />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className={`h-6 w-6 ${dark ? 'text-white' : ''}`} aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {
                      headerContent.MenuItems.map(item => (
                        <Link
                          key={item.Text}
                          to={item.Link}
                          className={`${dark ? 'text-white' : 'text-gray-900'} -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50`}
                        >
                          {item.Text}
                        </Link>
                      ))
                    }
                  </div>
                  <div className="py-6">
                    <a
                      href={headerContent.CTAButton.Text}
                      className={`${dark ? 'text-white' : 'text-gray-900'} -mx-3 mb-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50`}
                    >
                      {headerContent.CTAButton.Text}
                    </a>
                    <button onClick={() => handleTheme()} className='mb-4 font-semibold p-2 w-full bg-white rounded-lg flex items-center gap-3'>
                      {
                        dark ?
                          <SunIcon aria-hidden="true" className='h-5 w-5 flex-none text-gray-900' /> :
                          <MoonIcon aria-hidden="true" className='h-5 w-5 flex-none text-gray-900' />
                      }
                      {dark ? 'light mode' : 'dark mode'}
                    </button>
                    <div className='w-full flex items-center gap-2 justify-center'>
                      <input placeholder='Looking for something?' type='text' className='w-full p-2 rounded-lg outline-none border-2 border-gray-900 focus:ring-2 ring-emerald-500 ring-offset-2' />
                      <BlackPrimarySubmit text="search" className={`${dark ? 'bg-white text-gray-900' : ''} text-sm px-3 py-3 capitalize`} hasIcon={true} icon={<MagnifyingGlassIcon className={`${dark ? 'text-gray-900' : 'text-white'} w-5 h-5`} />} />
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </Transition.Child>
      </Transition>
    </>
  )
}

export default MobileHeader